/* src/pages/OAuthAuthorize/OAuthAuthorize.css */
.authorize-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.authorize-container h2 {
  margin-top: 0;
}
.authorize-content {
  margin-bottom: 1rem;
}
.authorize-buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 1rem; /* Add a fixed gap between the buttons */
}
.authorize-button,
.deny-button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
}
.authorize-button {
  background-color: #007bff;
  color: white;
}
.authorize-button:hover {
  background-color: #0056b3;
}
.deny-button {
  background-color: #dc3545;
  color: white;
}
.deny-button:hover {
  background-color: #c82333;
}

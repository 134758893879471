.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-header {
  background-color: #333;
  color: white;
  padding: 1rem;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.page-title {
  margin: 0;
  flex: 1;
  text-align: center;
}

.service-selector {
  position: absolute;
  right: 0;
}

.page-content {
  flex: 1;
  padding: 2rem;
  background-color: #f9f9f9;
}

.page-footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
}

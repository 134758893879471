.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  h2 {
    margin-top: 0;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* Valid input */
.form-group input.valid {
  border-color: #ccc;
}

/* Invalid input */
.form-group input.invalid {
  border-color: #f43f5e;
  outline: none;
}

.form-group input.invalid:focus {
  box-shadow: 0 0 0 2px rgba(244, 63, 94, 0.4);
  border-color: #f43f5e;
}

.form-group .error-message {
  color: #f43f5e;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: inline;
}

.login-button {
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.login-button.disabled {
  opacity: 0.5;

  cursor: not-allowed;
}

.login-button:hover {
  background-color: #0056b3;
}

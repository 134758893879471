.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  color: #d8000c;
  border: 1px solid #d8000c;
  border-radius: 4px;
  margin: 1rem 0;
  font-size: 1rem;
  text-align: center;
}

.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  font-size: 1.3rem;
  padding-right: 0.5rem;
}
